var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('report',{attrs:{"title":_vm.$route.name,"parameters":_vm.parameters,"loading":_vm.loading},on:{"refresh":function($event){return _vm.getReportData()},"exportReport":_vm.exportReport},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"5","offset":"7"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Filter","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1)],1),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.view == '' || _vm.view == 'summary'),expression:"view == '' || view == 'summary'"}],attrs:{"headers":_vm.summaryHeaders,"items":_vm.rptSummaryData,"search":_vm.filter,"items-per-page":200},scopedSlots:_vm._u([{key:"item.orderTotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney( item.orderTotal ))+" ")]}},{key:"item.commission",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney( item.commission ))+" ")]}},{key:"body.append",fn:function(ref){
var headers = ref.headers;
var items = ref.items;
return [(items.length > 0)?_c('tr',{staticClass:"totals"},_vm._l((headers),function(col,i){return _c('td',{key:i,class:col.align == 'right' ? 'text-right subtitle-2' : 'subtitle-2'},[_vm._v(" "+_vm._s(_vm.summarize( col, i ))+" ")])}),0):_vm._e()]}}])}),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.view == 'detail'),expression:"view == 'detail'"}],attrs:{"headers":_vm.detailHeaders,"items":_vm.rptDetailData,"search":_vm.filter,"items-per-page":200},scopedSlots:_vm._u([{key:"item.orderTotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney( item.orderTotal ))+" ")]}},{key:"item.commissionPercentage",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.commissionPercentage)+" ")]}},{key:"item.commission",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMoney( item.commission ))+" ")]}},{key:"body.append",fn:function(ref){
var headers = ref.headers;
var items = ref.items;
return [(items.length > 0)?_c('tr',{staticClass:"totals"},_vm._l((headers),function(col,i){return _c('td',{key:i,class:col.align == 'right' ? 'text-right subtitle-2' : 'subtitle-2'},[_vm._v(" "+_vm._s(_vm.summarize( col, i ))+" ")])}),0):_vm._e()]}}])})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }