<template>
    <div>
        <report :title="$route.name" :parameters="parameters" :loading="loading" @refresh="getReportData()" @exportReport="exportReport">
            <template v-slot:content>
                <v-row>
                    <v-col cols="5" offset="7">
                        <v-text-field v-model="filter" append-icon="mdi-magnify" label="Filter" single-line hide-details clearable></v-text-field>
                    </v-col>
                </v-row>
                
                <v-data-table :headers="summaryHeaders" :items="rptSummaryData" :search="filter" :items-per-page="200" v-show="view == '' || view == 'summary'">
                    <template v-slot:item.orderTotal="{ item }">
                        {{ formatMoney( item.orderTotal ) }}
                    </template>
                    <template v-slot:item.commission="{ item }">
                        {{ formatMoney( item.commission ) }}
                    </template>

                    <template v-slot:body.append="{ headers, items }">
                        <tr class="totals" v-if="items.length > 0">
                            <td v-for="(col, i) in headers" :key="i" :class="col.align == 'right' ? 'text-right subtitle-2' : 'subtitle-2'">
                                {{ summarize( col, i ) }}
                            </td>
                        </tr>
                    </template>
                </v-data-table>

                <v-data-table :headers="detailHeaders" :items="rptDetailData" :search="filter" :items-per-page="200" v-show="view == 'detail'">
                    <template v-slot:item.orderTotal="{ item }">
                        {{ formatMoney( item.orderTotal ) }}
                    </template>
                    <template v-slot:item.commissionPercentage="{ item }">
                        {{ item.commissionPercentage }}
                    </template>
                    <template v-slot:item.commission="{ item }">
                        {{ formatMoney( item.commission ) }}
                    </template>

                    <template v-slot:body.append="{ headers, items }">
                        <tr class="totals" v-if="items.length > 0">
                            <td v-for="(col, i) in headers" :key="i" :class="col.align == 'right' ? 'text-right subtitle-2' : 'subtitle-2'">
                                {{ summarize( col, i ) }}
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </template>
        </report>
    </div>
</template>

<script>
import Report from '@/components/Report.vue';
import { API } from '@/inc/api';

export default {
    data: () => ({
        parameters: [{ 
            type: 'date', 
            key: 'pdate', 
            label: 'Payment date', 
            value: [new Date().toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)], 
            visible: true, 
            icon: '' 
        },{ 
            type: 'select', 
            key: 'selectView', 
            label: 'View', 
            value: { id: 'summary', text: 'Summary' }, 
            visible: true, 
            icon: '',
            options: [
                { id: 'summary', text: 'Summary' }, 
                { id: 'detail', text: 'Detail' }
            ]
        }],
        summaryHeaders: [
            { text: 'Rep. Code', value: 'stylistCode', sortable: true, summarizeFn: '' },
            { text: 'Representative', value: 'stylistName', sortable: true, summarizeFn: '' },
            { text: 'Orders Total', value: 'orderTotal', align: 'right', sortable: true, summarizeFn: 'sum', formatter: 'money' },
            { text: 'Commission Perc.', value: 'commissionPercentage', align: 'right', sortable: true, summarizeFn: '' },
            { text: 'Commission', value: 'commission', align: 'right', sortable: true, summarizeFn: 'sum', formatter: 'money' },
            { text: 'Type', value: 'type', sortable: true, summarizeFn: '' },
        ],
        detailHeaders: [
            { text: 'Month', value: 'commissionMonth', sortable: true, summarizeFn: '' },
            { text: 'Order', value: 'orderId', sortable: true, summarizeFn: '' },
            { text: 'Rep. Code', value: 'stylistCode', sortable: true, summarizeFn: '' },
            { text: 'Representative', value: 'stylistName', sortable: true, summarizeFn: '' },
            { text: 'Total', value: 'orderTotal', align: 'right', sortable: true, summarizeFn: 'sum', formatter: 'money' },
            { text: 'Commission Perc.', value: 'commissionPercentage', align: 'right', sortable: true, summarizeFn: '' },
            { text: 'Commission', value: 'commission', align: 'right', sortable: true, summarizeFn: 'sum', formatter: 'money' },
            { text: 'Type', value: 'type', sortable: true, summarizeFn: '' },
        ],
        rptSummaryData: [],
        rptDetailData: [],
        filter: '',
        loading: false,
    }),
    computed: {
        view() {
            let view = '';
            view = this.parameters.find( p => {
                return p.key == 'selectView';
            });

            if( view == null || view == undefined )
                return '';

            return view.value.id;
        }
    },
    methods: {
        async getReportData() {
            let api = new API();

            try {
                this.loading = true;
                let res = await api.get(`/admin/rpt/dtmcommission/${this.parameters[0].value[0]}/${this.parameters[0].value[1]}/${( this.view == '' ? 'summary' : this.view )}`);
                this.loading = false;
                
                if( res.data.status === false ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return;
                }
                
                if( this.view == '' || this.view == 'summary')
                    this.rptSummaryData = res.data.data;
                else
                    this.rptDetailData = res.data.data;
            }
            catch(error) {
                this.loading = false;
                this.mxShowMessage( error, 'error' );
            }
        },
        summarize( col ) {
            let data = this.view == 'summary' || this.view == '' ? this.rptSummaryData : this.rptDetailData;
            let result = 0;

            if( data.length == 0 )
                return;

            // Summarize data
            if( col.summarizeFn == 'sum') {
                result = data.reduce( function(a, b) {
                    return parseFloat(a) + parseFloat(b[col.value] == null ? 0 : b[col.value]);
                }, 0);
            }

            // Apply formatter
            switch( col.formatter ) {
                case 'money': return this.formatMoney( result );
            }
        },
        exportReport( format ) {
            if( format == 'pdf' ) this.generatePdf();
            else if( format == 'csv' ) this.exportCsv();
        },
        generatePdf() {
            let headers = this.view == 'summary' || this.view == '' ? this.summaryHeaders : this.detailHeaders;
            let data = this.view == 'summary' || this.view == '' ? this.rptSummaryData : this.rptDetailData;

            data = this.createPdfArray( headers, data );
            this.exportPdf( headers, data );
        },
        exportCsv() {
            let headers = this.view == 'summary' || this.view == '' ? this.summaryHeaders : this.detailHeaders;
            let data = this.view == 'summary' || this.view == '' ? this.rptSummaryData : this.rptDetailData;
            let csvContent = "data:text/csv;charset=utf-8,";
            csvContent += this.createCsvArray(headers, data).map(item => item.join(',')).join("\n");

            var encodedUri = encodeURI(csvContent);
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", encodeURI( this.$route.name ) + ".csv");
            document.body.appendChild(link); // Required for FF

            link.click(); // This will download the data file named "my_data.csv".
        },
    },
    components: {
        Report
    }
}
</script>

<style scoped>
tr.totals td {
    background-color: #ececec;
    height: auto;
    border-top: 3px double #000;
}
</style>